@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato-Regular.eot');
  src: url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype'),
    url('../fonts/Lato-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato-Bold.eot');
  src: url('../fonts/Lato-Bold.woff') format('woff'),
    url('../fonts/Lato-Bold.ttf') format('truetype'),
    url('../fonts/Lato-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato-Light.eot');
  src: url('../fonts/Lato-Light.woff') format('woff'),
    url('../fonts/Lato-Light.ttf') format('truetype'),
    url('../fonts/Lato-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

html{
  // font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'Lato-Regular';
  overflow-x: hidden;
  line-height: 1.9;
  position: relative;
  color: #000;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
.compensate-for-scrollbar{
  margin-right: 0 !important;
}
//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

//半透明黑色遮罩
.root-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: none;

  &.mask-top {
    z-index: 10;
  }

  &.mask-bottom {
    z-index: 3;
  }
}

//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1450px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@keyframes rain {
  0% {
    opacity: 1;
    transform: translate3d(-50%,0,0);
  }

  50% {
    opacity: .5;
    transform: translate3d(-50%,10px,0);
  }

  100% {
    opacity: 0;
    transform: translate3d(-50%,20px,0);
  }
}

// 内页公共样式（除产品以外）
// banner图
.common-ny-banner{
  position: relative;
  background-color: $color-main-light;
  @include res(height,470px, 250 / 470);
  &::after{
    content: '';
    background-color: #043b79;
    position: absolute;
    right: 0;
    z-index: 0;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
    @include res(width,49.74%,(sm:103%));
    @include res(height,76%,68 / 76);
    @include res(top,50%, 50 / 50);
  }
  .bg-cont{
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    @include res(width,88%,97 / 88);
  }
}
//面包屑
.common-location{
  position: relative;
  z-index: 1;
  text-align: right;
  color: #fff;
  font-family: 'Lato-Light';
  @include res(margin-top,55px, 10 / 55);
}
//内页内容
.common-ny-cont{
  position: relative;
  z-index: 1;
  @include res(margin-bottom,100px, 50 / 100);
  .container{
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include res(display,flex,(sm:block));
    .right-menu{
      flex-shrink: 0;
      border-top: 1px solid #252d67;
      border-bottom: 1px solid #252d67;
      @include res(width,28%,(sm:100%));
      @include res(margin-top,115px, 30 / 115);
      @include res(margin-bottom,0, (sm:30px,xs:25px));
      ul{
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        @include res(display,block,(sm:flex));
        li{
          border-bottom: 1px solid #252d67;
          font-family: 'Lato-Light';
          padding:2px 0;
          position: relative;
          transition: all $anime-duration;
          @include res(width,100%,(sm:50%));
          @include res(font-size,24px,(lg:22px, mmd:20px, md:16px));
          @include res(padding-left,0,(sm:10px,xs:5px));
          @include res(border-right,none,(sm:1px solid #252d67));
          &:nth-child(2n){
            border-right: none;
          }
          &:last-child{
            border-bottom: none;
          }
          &:nth-last-child(2){
            @include res(border-bottom,1px solid #252d67,(sm:none));
          }
          &::after{
            content: '';
            display: block;
            border-radius: 50%;
            background: #cccccc;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: all $anime-duration;
            @include res(width,8px, 5 / 8);
            @include res(height,8px, 5 / 8);
          }
          &:hover{
            color: #469fdd;
          }
          &.active{
            color: #469fdd;
            &::after{
              background: #469fdd;
            }
          }
          a{
            display: block;
          }
        }
      }
    }
    .ny-cont{
      flex-shrink: 0;
      @include res(width,58.3%,(mmd:65%,sm:100%));
      .common-ny-title{
        color: #149ad8;
        line-height: 1.2;
        font-family: 'Lato-bold';
        @include res(text-align,left,(sm:center));
        @include res(margin-bottom,85px, 25 / 85);
        @include res(font-size,70px,(lg:60px, mmd:50px, md:40px,sm:30px,xs:20px));
      }
      .common-desc{
        color: $color-text;
        @include res(font-size,$text-size-md-base,$text-size-md-ratio);
        @include res(margin-bottom,65px, 20 / 65);
      }
    }
  }
}

.page{
  display: flex;
  align-items: stretch;
  justify-content: center;
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #469fdd;
    color: #fff;
    transition: all $anime-duration;
    @include res(width,40px, 30 / 40);
    @include res(height,40px, 30 / 40);
    @include res(margin-left,8px, 4 / 8);
    @include res(margin-right,8px, 4 / 8);
    &:hover{
      background: #252d67;
    }
    &.active,&.hover{
      background: #252d67;
      @include res(width,75px, 50 / 75);
    }
  }
}
#page ul{
  display: flex;
  align-items: stretch;
  justify-content: center;
  li{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #469fdd;
    color: #fff;
    transition: all $anime-duration;
    cursor: pointer;
    @include res(width,40px, 30 / 40);
    @include res(height,40px, 30 / 40);
    @include res(margin-left,8px, 4 / 8);
    @include res(margin-right,8px, 4 / 8);
    &:hover{
      background: #252d67;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
    &.xl-active{
      background: #252d67;
      @include res(width,75px, 50 / 75);
    }
  }
}