@import "../../assets/styles/utils";

footer{
    background: url(./images/footer_black_bg.png) no-repeat left center $color-main-dark;
    background-size: 100% 100%;
    position: relative;
    color: #fff;
    overflow: hidden;
    &::before{
        content: '';
        display: block;
        width: 71.35%;
        // height: 40%;
        height: 395px;
        max-height: 395px;
        background: rgba(70,159,221,.24);
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
        position: absolute;
        top: 0;
        left: 0;
        @include res(height,395px, 220 / 395);
    }
    &::after{
        content: '';
        display: block;
        width: 38%;
        height: 126px;
        background: url(./images/footer_logo.png) no-repeat right top;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 43%;
    }
    .footer{
        position: relative;
        z-index: 1;
        @include res(padding-top,65px, 10 / 65);
        .footer-section1{
            position: relative;
            border-top: 1px solid rgba(255,255,255,.2);
            @include res(display,block,(xs:none));
            @include res(margin-bottom,100px, 50 / 100);
            @include res(padding-top,60px, 20 / 60);
            ul{
                display: flex;
                align-items: flex-start;
                li{
                    @include res(margin-right,100px, 20 / 100);
                    .menu-title{
                        font-family: 'Lato-Light';
                        font-weight: lighter;
                        line-height: .9;
                        @include res(margin-bottom,35px, 15 / 35);
                        @include res(font-size,$title-size-md-base,$title-size-md-ratio);
                    }
                    .sub-menu{
                        font-family: 'Lato-Bold';
                        line-height: 1.6;
                        @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                        a{
                            display: block;
                            transition: all $anime-duration;
                            position: relative;
                            left: 0;
                            &:hover{
                                left: 5px;
                            }
                        }
                    }
                }
            }
            .language{
                position: absolute;
                right: 0;
                background: #333a71;
                border-radius: 5px;
                @include res(display,block,(sm:none));
                @include res(padding-left,15px, 10 / 15);
                @include res(padding-right,15px, 10 / 15);
                @include res(padding-top,5px, 5 / 5);
                @include res(padding-bottom,5px, 5 / 5);
                @include res(top,65px, 20 / 65);
                &:hover{
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    .sub-language{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .icon-yuyan{
                    @include res(font-size,22px, 16 / 22);
                }
                span{
                    font-family: 'Lato-Bold';
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    @include res(margin-left,10px, 10 / 10);
                    @include res(margin-right,20px, 10 / 20);
                }
                .sub-language{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 100%;
                    background: #333a71;
                    text-align: center;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    overflow: hidden;
                    opacity: 0;
                    visibility: hidden;
                    transition: all $anime-duration;
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    a{
                        display: block;
                        transition: all $anime-duration;
                        @include res(padding-top,10px, 5 / 10);
                        @include res(padding-bottom,10px, 5 / 10);
                        &:hover{
                            background: rgba(255,255,255,.5);
                            color: $color-main-dark;
                        }
                    }
                }
            }
        }
        .footer-section2{
            align-items: flex-start;
            justify-content: space-between;
            @include res(display,flex,(xs:block));
            .left{
                flex-shrink: 0;
                background-color: #fff;
                color: #000;
                box-sizing: border-box;
                transition: all $anime-duration;
                @include res(padding-top,40px, 10 / 40);
                @include res(padding-left,40px, 10 / 40);
                @include res(padding-right,40px, 10 / 40);
                @include res(width,50%,(xs:100%));
                @include res(display,null,(xs:none));
                &:hover{
                    color: #fff;
                    background-color: rgba(255,255,255,.1);
                    .desc{
                        color: #fff;
                    }
                }
                .desc{
                    font-family: 'Lato-Light';
                    color: $color-text;
                    transition: all $anime-duration;
                    @include res(margin-bottom,20px, 10 / 20);
                }
                .title{
                    font-family: 'Lato-Light';
                    line-height: 1.2;
                    display: -webkit-box;    
                    -webkit-box-orient: vertical;    
                    -webkit-line-clamp: 2;    
                    overflow: hidden;
                    @include res(margin-bottom,25px, 10 / 25);
                    @include res(font-size,28px,$title-size-md-ratio);
                }
                .link{
                    border-top: 1px dashed #cccccc;
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-family: 'Lato-Bold';
                        @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                        @include res(padding-top,15px, 10 / 15);
                        @include res(padding-bottom,15px, 10 / 15);
                        .iconfont{
                            transition: transform $anime-duration;
                            @include res(font-size,20px,16 / 20);
                        }
                        &:hover{
                            .iconfont{
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
            .right{
                flex-shrink: 0;
                @include res(padding-top,15px, 0 / 15);
                @include res(width,44.5%,(xs:100%));
                @include res(text-align,null,(xs:center));
                .title{
                    font-family: 'Lato-Bold';
                    @include res(margin-bottom,45px, 10 / 45);
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                }
                .tel{
                    font-family: 'Lato-Light';
                    @include res(font-size,36px,(lg:32px, mmd:28px, md:20px));
                    @include res(margin-bottom,25px, 10 / 25);
                }
                .link{
                    display: flex;
                    align-items: center;
                    @include res(justify-content,flex-start,(xs:center));
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-color: #fff;
                        color: $color-main-dark;
                        transition: all $anime-duration;
                        @include res(width,55px, 40 / 55);
                        @include res(height,55px, 40 / 55);
                        @include res(margin-right,25px, 15 / 25);
                        &:last-child{
                            margin-right: 0;
                        }
                        &:hover{
                            background-color: rgba(255,255,255,.1);
                            color: #fff;
                        }
                        .iconfont{
                            @include res(font-size,24px, 18 / 24);
                        }
                    }
                }
            }
        }
        .copyright{
            align-items: center;
            justify-content: space-between;
            line-height: 1.5;
            @include res(text-align,null,(xs:center));
            @include res(display,flex,(xs:block));
            @include res(padding-top,20px, 10 / 20);
            @include res(padding-bottom,20px, 10 / 20);
            @include res(margin-top,0,(xs:10px));
            @include res(margin-left,null,(sm:-20px,xs:0));
            @include res(margin-right,null,(sm:-20px,xs:0));
            .beian{
                // a,span{
                //     @include res(display,null,(xs:block));
                // }
            }
            span{
                margin-left: 10px;
                margin-right: 10px;
            }
            .beian+div{
                a:first-child,a:first-child+span{
                    @include res(display,null,(md:none));
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    footer .footer .footer-section1 ul li{
        margin-right: 50px;
    }
}